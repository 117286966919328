import Stars from "components/Stars";
import { Link } from "gatsby";
import React from "react";
import { ls } from "utility/helper";

const OfferCards = ({ to = "/" }) => {
  const isSSR = typeof window !== "undefined";
  const { id } = ls("profile") || {};
  return (
    <>
      <div className="cards-ads">
        <div className="card card--ads">
          <div className=" card--titleAds grayBg">مجانا</div>
          <div className="card--contentAds">
            <p>
              يمكنك من رؤية حل تمرين صعب (5
              <Stars />) في اليوم
            </p>

            <p>
              100 نجمة فضية
              <span style={{ color: "silver", fontSize: "1.5em" }}>
                {" "}
                &#9733;{" "}
              </span>
              كل 24 ساعة *
            </p>
          </div>
          <div>
            <Link
              className="button grayBg"
              style={{
                width: "100%",
                color: "white",
                fontSize: "1em",
                borderRadius: "0 0 5px 5px",
              }}
              to="/Signin"
            >
              سجل الآن
            </Link>
          </div>
        </div>
        <div className="card card--ads">
          <div className="red card--titleAds">
            5دج/
            <Stars />
          </div>
          <div className="card--contentAds">
            <p>إذا أردت رؤية حل أكثر من تمرين في اليوم</p>
            <p>
              يجب أن تشتري النجوم الذهبية
              <Stars />
            </p>
            <p>
              بسعر 5دج لكل نجمة
              <Stars />
              فقط!!!
            </p>
          </div>
          <div>
            <Link
              onClick={() =>
                isSSR &&
                window?.gtag("event", "goToOffers", {
                  id: id ? "isUser" : "notUser",
                  type: 500,
                })
              }
              className="button red"
              style={{
                width: "100%",
                color: "white",
                fontSize: "1em",
                borderRadius: "0 0 5px 5px",
              }}
              to={to}
            >
              عبئ الآن
            </Link>
          </div>
        </div>
        <div className="card card--ads">
          <div className="blue card--titleAds">
            3دج/
            <Stars />
          </div>
          <div className="card--contentAds">
            <p>إذا اشتريت أكثر من 600 نجمة</p>
            <p>
              يصبح السعر 3دج لكل نجمة
              <Stars />
              فقط!!!
            </p>
          </div>
          <div>
            <Link
              onClick={() =>
                isSSR &&
                window?.gtag("event", "goToOffers", {
                  id: id ? "isUser" : "notUser",
                  type: 3000,
                })
              }
              className="button blue"
              style={{
                width: "100%",
                color: "white",
                fontSize: "1em",
                borderRadius: "0 0 5px 5px",
              }}
              to={to}
            >
              عبئ الآن
            </Link>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <p>
          * حلول التمارين المفعلة بالنحوم الفضية تبقى مفعلة
          <b> 3 أيام </b>
          فقط
        </p>
        <p>
          ** حلول التمارين المفعلة بالنحوم الذهبية تبقى مفعلة
          <b> لمدة سنة كاملة</b>
        </p>
      </div>
    </>
  );
};

export default OfferCards;
